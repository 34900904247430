import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Logo from "./../../Images/logo.png";
import { FiMenu } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import ConnectButton from "../ConnectButton";

export default function Header(props) {
  const [NavBar, setNavBar] = useState(false);

  const handleNavBar = () => {
    setNavBar(!NavBar);
  };

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setNavBar(false);
    } else {
      setNavBar(true);
    }
  }, [window.innerWidth]);

  return (
    <div className="header">
      <div>
        <img src={Logo} alt="Company logo" className="logo" />
      </div>
      {NavBar && (
        <div className="navbar">
          <div id="sidebarShadow1"></div>
          <div id="sidebarShadow2"></div>
          <i className="menuCloseBtn" onClick={handleNavBar}>
            <RxCross1 />
          </i>
          <Link to="/ecosystem" className="navlink">Ecosystem</Link>
          <Link to="/community" className="navlink">Community</Link>
          <Link to="/governance" className="navlink">Governance</Link>
          <Link to="/swap" className="navlink">Swap</Link>
          <Link to='/blogs' className="navlink">Blog</Link>
          <Link to="/faq" className="navlink">FAQ</Link>
        </div>
      )}
      <div id="mobileConnectButton">
        <ConnectButton />
      </div>
      <i className="menuIcon" onClick={handleNavBar}>
        <FiMenu />
      </i>
      <div className="launchButtonWrapper">
        {
          props.type == "swap" ?
            <ConnectButton />
            :
            <button className="launchButton">Launch App</button>
        }

      </div>
    </div>
  );
}
