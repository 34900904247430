
const ContractABI = require("../Contracts/Contract.json");
const SwapABI = require("../Contracts/SwapABI.json");
const USDTABI = require("../Contracts/USDTABI.json")
const arr = {
  Contract: "0x9c63af96b60fCA0eB8aA123EEBC8c74306De479b",
  ContractABI: ContractABI,
  SwapContract:"0xfedB2692D990125DAF9527a40E64553e4730f86a",
  SwapABI: SwapABI,
  USDTContract:"0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  USDTABI:USDTABI
};

const ContractDetails = arr;
module.exports = ContractDetails;
