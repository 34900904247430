import React from 'react'
import './MarketingValue.css'
import etherium from '../../../Images/ether.png'
import SmallArrow from '../../../Images/smallArrow.svg'
import Wave from '../../../Images/Col7-wave.svg'
function MarketingValue() {

  // const data = [
  //   {
  //     column1:
  //       <div className='MarketingValue-tableData-col1'>
  //         <img src={etherium} alt="" />
  //         <span>ETH</span>
  //       </div>,
  //     column2:
  //       <div className='MarketingValue-tableData-col2'>

  //       </div>,
  //     column3: 'Data 3',
  //     column4: 'Data 4',
  //     column5: 'Data 5',
  //     column6: 'Data 6',
  //     column7: 'Data 7'
  //   },


  // ];

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr className='mb-4'>
            <th className='pb-4'>Name</th>
            <th className='pb-4'>Price</th>
            <th className='pb-4'>%7d</th>
            <th className='pb-4'>%24h</th>
            <th className='pb-4'>Market Cap</th>
            <th className='pb-4'>24h Volume</th>
            <th className='pb-4'>Last 7 days</th>
          </tr>
        </thead>
        <tbody >
          {Array.from({ length: 10 }, (_, index) => (
            <>
              <tr style={{ background: "#00081E" }}>
                <td className='MarketingValue-tableData-start-col'>
                  <div className='MarketingValue-tableData-col1'>
                    <img src={etherium} alt="" />
                    <span>ETH</span>
                  </div>
                </td>
                <td>
                  <div className='MarketingValue-tableData-col2'>
                    <span>
                      $3,210.00
                    </span>
                  </div>
                </td>
                <td>
                  <div className='MarketingValue-tableData-col3'>
                    <img src={SmallArrow} alt="" />
                    <span>1.09%</span>
                  </div>
                </td>
                <td>
                  <div className='MarketingValue-tableData-col3'>
                    <img src={SmallArrow} alt="" />
                    <span>1.12%</span>
                  </div>
                </td>
                <td>
                  <div className='MarketingValue-tableData-col5'>
                    <span>$325,210,569.198</span>
                  </div>
                </td>
                <td>
                  <div className='MarketingValue-tableData-col5'>
                    <span>$325,210,569</span>
                  </div>
                </td>
                <td className='MarketingValue-tableData-end-col'>
                  <div className='MarketingValue-tableData-col7'>
                    <img src={Wave} alt="" />
                  </div>
                </td>
              </tr>
              <tr style={{ height: "5px", background: "transparent" }}>
                <td colspan="2" style={{ background: "transparent" }}></td>
              </tr>
            </>
          ))}


        </tbody>
      </table>
    </div>
  )
}

export default MarketingValue