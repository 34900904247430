import ContractDetails from "../Contracts/ContractDetails";
import Web3 from 'web3';

export const fetchEvents = async () => {
    const { BigInt } = window;
    try {
      const web3Instance = new Web3(new Web3.providers.HttpProvider('https://quick-dawn-mound.matic.quiknode.pro/ef8feb268f836873d6b947f5f00822b8e94015cc/'));

      const contractAddress = ContractDetails.Contract;
      const contractABI = ContractDetails.ContractABI;

      const contract = new web3Instance.eth.Contract(contractABI, contractAddress);

      const latestBlockNumber = await web3Instance.eth.getBlockNumber();

      const blockFromFetch = BigInt(57797879);
      const blockToFetch = BigInt(latestBlockNumber);
      let fromBlock = blockFromFetch;

      const allEvents = [];

      // Set chunk size considering the node's capacity (9000 blocks/second)
      const chunkSize = 9000;

      while (fromBlock <= blockToFetch) {
        const toBlock = (Number(fromBlock) + chunkSize - 1 > Number(blockToFetch)) ? 'latest' : Number(fromBlock) + chunkSize - 1;

        const evt = await contract.getPastEvents('swap', {
          fromBlock: Number(fromBlock),
          toBlock: toBlock === 'latest' ? toBlock : Number(fromBlock) + chunkSize - 1,
        });

        allEvents.push(...evt);
        fromBlock = (toBlock === 'latest') ? blockToFetch + BigInt(1) : BigInt(toBlock) + BigInt(1);
      }
return allEvents;
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };