import React, { useState, useRef, useEffect } from 'react';
import './Swap.css';
import Header from '../../Components/Header/Header';
import { Col, Container, Row } from 'react-bootstrap';
import loops from './../../Images/Looper-3.svg';
import swapIcon from './../../Images/swapIcon.svg';
import { MdArrowDropDown, MdModeEdit } from "react-icons/md";
import etherium from './../../Images/ether.png';
import webApp from './../../Images/Group.svg';
import dai from './../../Images/dai.png';
import devDocs from './../../Images/web-programming 1.svg';
import DWCLogo from './../../Images/DeFiLogo.png';
import USDC from './../../Images/usdc.png';
import USDT from './../../Images/usdt.png';
import SmallArrow from './../../Images/smallArrow.svg';
import ConnectButton from '../../Components/ConnectButton';
import { useSelector } from 'react-redux';
import { ethers } from "ethers";
import ContractDetails from '../../Contracts/ContractDetails';
import { Data, toastFailed, toastSuccess } from '../../Common/Data';
import GetChainId from '../../Common/GetChainId';
import Crosschain from '../../Images/Crosschain.png'
import { GetConversionRate } from '../../Common/GetConversionRate';
import { GetTokenDecimal } from '../../Common/GetTokenDecimal';
import axios from 'axios';
import MATIC from '../../Images/MATIC.png'
import RNDR from '../../Images/RNDR'
import AAVE from '../../Images/AAVE'
import MANA from '../../Images/MANA.png'
import WOO from '../../Images/WOO.svg'
import CRV from '../../Images/CRV.webp'
import OCEAN from '../../Images/OCEAN.png'
import OM from '../../Images/OM.png'
import SUPER from '../../Images/SUPER.webp'
import ANKR from '../../Images/ANKR.png'
import TRAC from '../../Images/TRAC.png'
import MASK from '../../Images/MASK.webp'
import FRAX from '../../Images/FRAX.webp'
import TEL from '../../Images/TEL.webp'
import PHA from '../../Images/PHA.webp'
import ELON from '../../Images/ELON.webp'
import GNS from '../../Images/GNS.png'
import GHST from '../../Images/GHST.png'
import ALI from '../../Images/ALI.webp'
import QUICK from '../../Images/QUICK.png'
import VOLT from '../../Images/VOLT.webp'
import KLIMA from '../../Images/KLIMA.webp'
import PUSH from '../../Images/PUSH.png'
import MEE from '../../Images/MEE.webp'
import SWCH from '../../Images/SWCH.webp'
import UFI from '../../Images/UFI.webp'
import PBR from '../../Images/PBR.webp'
import XED from '../../Images/XED.jpeg'
import KOM from '../../Images/KOM.png'
import DSLA from '../../Images/DSLA.jpg'
import CONE from '../../Images/CONE.png'
import trsnsArrow from '../../Images/transactionArrow.png'
import Loader from '../../Components/Loader/Loader';
import MarketingValue from './Tables/MarketingValue';
import { FaArrowRight, FaFontAwesome } from 'react-icons/fa';
import { AiOutlineRetweet } from 'react-icons/ai';
import { FaCircleArrowDown } from 'react-icons/fa6';
import ToggleSwitch from '../../Components/Toggel/Toggle';
import Footer from '../../Components/Footer/Footer'
import { VscSettingsGear } from 'react-icons/vsc';
import { GetTokenBalance } from '../../Common/GetTokenBalance';
import { fetchEvents } from '../../Common/FetchEvens';
// import TransactionTable from './TransactionStatus/TransactionStatus';
// Dropdown component
const Dropdown = ({ options, selectedOption, setSelectedOption, isOpen, setIsOpen, dropdownRef, handleOptionClick }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownIconClass = isOpen ? 'dropdown-icon rotated' : 'dropdown-icon';

    // Filter options based on search term
    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div className="selected-option" onClick={() => setIsOpen(!isOpen)}>
                {selectedOption ? (
                    <>
                        {selectedOption.imageSrc ? (
                            <img src={selectedOption.imageSrc} alt={selectedOption.label} />
                        ) : (
                            <div className='imgIcon'>{selectedOption.imgIcon}</div>
                        )}
                        <span>{selectedOption.label}</span>
                    </>
                ) : (
                    <span>Select an option</span>
                )}
                <i className={dropdownIconClass}><MdArrowDropDown /></i>
            </div>
            {isOpen && (
                <div className="options-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                    <div className="options">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map(option => (
                                <div key={option.value} className="option" onClick={() => handleOptionClick(option)}>
                                    {option.imageSrc ? (
                                        <img src={option.imageSrc} alt={option.label} />
                                    ) : (
                                        <div className='imgIcon'>{option.imgIcon}</div>
                                    )}
                                    <span>{option.label}</span>
                                </div>
                            ))
                        ) : (
                            <div className="no-options">No options found</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
const ServiceCurrencyPrices = () => {

    return (
        <div className='serviceCurrencyPrices'>
            <div className='serviceCurrencyPricesLeft'>
                <img src={etherium} alt="" />
                <p>Ethereum</p>
                <h5>ETH</h5>
            </div>
            <div className='serviceCurrencyPricesRight'>
                <p>$3,210.00</p>
                <img src={SmallArrow} alt="" />
                <h5>1.09%</h5>
            </div>
        </div>
    );
};

//////////////////////////
const tokenMetadata = {
    '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359': { label: 'USDC', imageSrc: USDC , decimals: 6},
    '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063': { label: 'DAI', imageSrc: dai , decimals: 18},
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619': { label: 'ETH', imageSrc: etherium , decimals: 18},
    '0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F': { label: 'DWC', imageSrc: DWCLogo , decimals: 18},
    '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': { label: 'USDT', imageSrc: USDT , decimals: 6},
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270': { label: 'MATIC', imageSrc: MATIC , decimals: 18},
    '0x61299774020dA444Af134c82fa83E3810b309991': { label: 'RNDR', imageSrc: RNDR , decimals: 18},
    '0x5fe2B58c013d7601147DcdD68C143A77499f5531': { label: 'GRT', imgIcon: 'GR' , decimals: 18},
    '0xD6DF932A45C0f255f85145f286eA0b292B21C90B': { label: 'AAVE', imageSrc: AAVE , decimals: 18},
    '0x3C69d114664d48357d820Dbdd121a8071eAc99bf': { label: 'GALA', imgIcon: 'GL' , decimals: 18},
    '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4': { label: 'MANA', imageSrc: MANA , decimals: 18},
    '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603': { label: 'WOO', imageSrc: WOO , decimals: 18},
    '0x172370d5Cd63279eFa6d502DAB29171933a610AF': { label: 'CRV', imageSrc: CRV , decimals: 18},
    '0x282d8efCe846A88B159800bd4130ad77443Fa1A1': { label: 'OCEAN', imageSrc: OCEAN , decimals: 18},
    '0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea': { label: 'OM', imageSrc: OM , decimals: 18},
    '0xa1428174F516F527fafdD146b883bB4428682737': { label: 'SUPER', imageSrc: SUPER , decimals: 18},
    '0x101A023270368c0D50BFfb62780F4aFd4ea79C35': { label: 'ANKR', imageSrc: ANKR , decimals: 18},
    '0x4287F07CBE6954f9F0DecD91d0705C926d8d03A4': { label: 'TRAC', imageSrc: TRAC , decimals: 18},
    '0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7': { label: 'MASK', imageSrc: MASK , decimals: 18},
    '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89': { label: 'FRAX', imageSrc: FRAX , decimals: 18},
    '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a': { label: 'SUSHI', imgIcon: 'SU' , decimals: 18},
    '0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32': { label: 'TEL', imageSrc: TEL , decimals: 18},
    '0x8DE5B80a0C1B02Fe4976851D030B36122dbb8624': { label: 'VANRY', imgIcon: 'VN' , decimals: 18},
    '0xE3322702BEdaaEd36CdDAb233360B939775ae5f1': { label: 'TRB', imgIcon: 'TR' , decimals: 18},
    '0x6a3E7C3c6EF65Ee26975b12293cA1AAD7e1dAeD2': { label: 'PHA', imageSrc: PHA , decimals: 18},
    '0xE0339c80fFDE91F3e20494Df88d4206D86024cdF': { label: 'ELON', imageSrc: ELON , decimals: 18},
    '0xE5417Af564e4bFDA1c483642db72007871397896': { label: 'GNS', imageSrc: GNS , decimals: 18},
    '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590': { label: 'STG', imgIcon: 'ST' , decimals: 18},
    '0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7': { label: 'GHST', imageSrc: GHST , decimals: 18},
    '0xbFc70507384047Aa74c29Cdc8c5Cb88D0f7213AC': { label: 'ALI', imageSrc: ALI , decimals: 18},
    '0x8a16D4bF8A0a716017e8D2262c4aC32927797a2F': { label: 'VCNT', imgIcon: 'VN' , decimals: 18},
    '0x5F32AbeeBD3c2fac1E7459A27e1AE9f1C16ccccA': { label: 'FAR', imgIcon: 'FA' , decimals: 18},
    '0xB5C064F955D8e7F38fE0460C556a72987494eE17': { label: 'QUICK', imageSrc: QUICK , decimals: 18},
    '0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4': { label: 'ROUTE', imgIcon: 'RT' , decimals: 18},
    '0x7f67639Ffc8C93dD558d452b8920b28815638c44': { label: 'LIME', imgIcon: 'LM' , decimals: 18},
    '0x7f792db54B0e580Cdc755178443f0430Cf799aCa': { label: 'VOLT', imageSrc: VOLT , decimals: 18},
    '0x65A05DB8322701724c197AF82C9CaE41195B0aA8': { label: 'FOX', imgIcon: 'FX' , decimals: 18},
    '0x4e78011Ce80ee02d2c3e649Fb657E45898257815': { label: 'KLIMA', imageSrc: KLIMA , decimals: 18},
    '0x200C234721b5e549c3693CCc93cF191f90dC2aF9': { label: 'METAL', imgIcon: 'MT' , decimals: 18},
    '0x3fb83A9A2c4408909c058b0BfE5B4823f54fAfE2': { label: 'BCUT', imgIcon: 'BU' , decimals: 18},
    '0xe78649874bcDB7a9D1666E665F340723a0187482': { label: 'BIM', imgIcon: 'BM' , decimals: 18},
    '0x36FE11B6d5c9421F68d235694Fe192B35e803903': { label: 'RWA', imgIcon: 'RA' , decimals: 18},
    '0x58001cC1A9E17A20935079aB40B1B8f4Fc19EFd1': { label: 'PUSH', imageSrc: PUSH , decimals: 18},
    '0x6109cB051c5C64093830121ed76272aB04bBDD7C': { label: 'PROS', imgIcon: 'PR' , decimals: 18},
    '0x2C8e0E04c68Da643cD3F87B05C33E58E5D7cEDc8': { label: 'GURU', imgIcon: 'GU' , decimals: 18},
    '0xF20eDC761DAbB8AC1a58b46F3E51265FeDAe13E3': { label: 'ROCO', imgIcon: 'RO' , decimals: 18},
    '0x524f540e12C6bC7Ec7Db53aF05Fae1aD8C09178E': { label: 'ODDZ', imgIcon: 'OZ' , decimals: 18},
    '0xcCe66132a9F84A9cc9636403EdB75aB840cED3b4': { label: 'MOVER', imgIcon: 'MV' , decimals: 18},
    '0x6f6933B0DdF133bb61848e03dFf26F24D00B507C': { label: 'XDB', imgIcon: 'XD' , decimals: 18},
    '0xD0d88Bf8f419d54C770fD6Fd1b2f29288CBac0aa': { label: 'SPHYNX', imgIcon: 'SY' , decimals: 18},
    '0x4Fe5851C9AF07df9E5Ad8217Afae1Ea72737Ebda': { label: 'TRAXX', imgIcon: 'TX' , decimals: 18},
    '0x771602F22B26d0EBa6B46d5563E6BfFC3CDBB20E': { label: 'XCAD', imgIcon: 'XC' , decimals: 18},
    '0xcA99d578e6451fc19Ac51BD41e3AEAC83C7a6eC6': { label: 'SWISE', imgIcon: 'SW' , decimals: 18},
    '0xc3d088842dcf02C13699F936BB83DFBBc6f721Ab': { label: 'MTA', imgIcon: 'MT' , decimals: 18},
    '0x6F58B721a34a4f542AB6e9aA2bC1f8b91ECF0b8F': { label: 'dHEDGE', imgIcon: 'DH' , decimals: 18},
    '0x66C19CA0a00CCdB6233Cb0f85A61205C2De89BB4': { label: 'EQIFi', imgIcon: 'EQ' , decimals: 18},
    '0x270617F8208910a1fae4db8a6336ff7A6DcbB6B3': { label: 'YEL', imgIcon: 'YL' , decimals: 18},
    '0x4E7f04fc244362b0E8EFBe291A8D6f74a25b7f8D': { label: 'gOHM', imgIcon: 'OH' , decimals: 18},
    '0x432C6CA6cDEB91e375F09a8AD5B0013ACDF7C1DB': { label: 'PIKA', imgIcon: 'PI' , decimals: 18},
  };

const TransactionStatus = () => {
    const {BigInt} = window;
    const [evtData, setevtData] = useState([])
    const getEvt = async()=>{
        const evt = await fetchEvents();
        console.log('metadata',evt[0].timestamp);
        setevtData(evt);
         
    }
    useEffect(() => {
    getEvt()
    }, [])
    
    const transactions = [
        {
            currencyFrom: 'USDT',
            amountFrom: 245,
            currencyTo: 'ETH',
            amountTo: 0.201602,
            date: '2024-05-09 17:30:33',
            status: 'To be processed',
        },
        {
            currencyFrom: 'USDT',
            amountFrom: 245,
            currencyTo: 'ETH',
            amountTo: 0.201602,
            date: '2024-05-09 17:30:33',
            status: 'Success',
        },
        {
            currencyFrom: 'USDT',
            amountFrom: 245,
            currencyTo: 'ETH',
            amountTo: 0.201602,
            date: '2024-05-09 17:30:33',
            status: 'To be processed',
        },
        {
            currencyFrom: 'USDT',
            amountFrom: 245,
            currencyTo: 'ETH',
            amountTo: 0.201602,
            date: '2024-05-09 17:30:33',
            status: 'Success',
        },
        {
            currencyFrom: 'USDT',
            amountFrom: 245,
            currencyTo: 'ETH',
            amountTo: 0.201602,
            date: '2024-05-09 17:30:33',
            status: 'To be processed',
        },
        {
            currencyFrom: 'USDT',
            amountFrom: 245,
            currencyTo: 'ETH',
            amountTo: 0.201602,
            date: '2024-05-09 17:30:33',
            status: 'Success',
        },
    ];
    const [topPair, setTopPair] = useState(null);

    useEffect(() => {
      const fetchTopPair = async () => {
        try {
          const apiKey = 'a426ff5f-f4fd-499f-8a79-2870e3cb470c'; // Replace with your actual API key
          const params = {
            'X-CMC_PRO_API_KEY': 'a426ff5f-f4fd-499f-8a79-2870e3cb470c',
          };
          
          // Set up the request headers
          const headers = {
            'X-CMC_PRO_API_KEY': 'a426ff5f-f4fd-499f-8a79-2870e3cb470c', // This is a test API Key for the sandbox environment
            'Accept': 'application/json'
          };
          const response = await axios.get(`https://sandbox-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?CMC_PRO_API_KEY=a426ff5f-f4fd-499f-8a79-2870e3cb470c`
            
          );
  
          // Assuming the data structure from the response
          const topPairData = response; // Get the top pair
          console.log("cmc data",topPairData)
          setTopPair(topPairData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchTopPair();
    }, []);
    return (
        <div className="transaction-status">
            {evtData.map((transaction, index) => (
                <CurrencyExchange
                    key={index}
                    currencyFrom={tokenMetadata[transaction?.returnValues?.tokenIn]?.label}
                    amountFrom={parseFloat(transaction?.returnValues?.amountIn)/(10**(tokenMetadata[transaction?.returnValues?.tokenIn]?.decimals))
                    }
                    currencyTo={tokenMetadata[transaction?.returnValues?.tokenOut]?.label}
                    amountTo={parseFloat(transaction?.returnValues?.amountOut)/(10**(tokenMetadata[transaction?.returnValues?.tokenOut]?.decimals))}
                    date={transaction?.timestamp}
                    status={transaction.status}
                    img1 = {tokenMetadata[transaction?.returnValues?.tokenIn]?.imageSrc}
                    img2 = {tokenMetadata[transaction?.returnValues?.tokenOut]?.imageSrc}
                />
            ))}
        </div>
    );
};
const CurrencyExchange = ({ currencyFrom, amountFrom, currencyTo, amountTo, date, status,img1,img2 }) => {
    const statusClass = status === 'Success' ? 'success' : 'processing';
    // console.log('object amount',amountTo)

    return (
        <div className="transactionDetails">
            <img src={trsnsArrow} alt="" className='trsnsArrow' />
            <div className={`transaction-entry ${statusClass}`}>

                <div className="transaction-details">
                    <div className="currency">
                        <img src={img1} alt="" className='currency-img' />
                        <span className="currency-icon">{currencyFrom}</span>
                        <span className="currency-amount">{Number(amountFrom)}</span>
                    </div>
                    <div className="currency">
                        <img src={img2} alt="" className='currency-img' />
                        <span className="currency-icon">{currencyTo}</span>
                        <span className="currency-amount">{Number(amountTo)}</span>
                    </div>
                </div>
                <div className="transaction-footer">
                    <span className="date">{date}</span>
                    <span className={`status ${statusClass}`}>{status}</span>
                </div>
            </div>
        </div>
    );
};
const TransactionTable = () => {
    return (
        <div className="table-container">
            {/* <div className="date-selectors">
                <div className="date-selector">
                    <label>Start:</label>
                    <div className='InputBorder'><input type="date" value="2024-05-09" className="date-input" /></div>
                </div>
                <div className="date-selector">
                    <label>End:</label>
                    <div className='InputBorder'><input type="date" value="2024-05-09" className="date-input" /></div>
                </div>
            </div> */}
            {/* <div style={{ border: '.1px solid rgb(83, 66, 190)', margin: '10px 10px 30px 10px' }}></div> */}
            <TransactionStatus />
        </div>
    );
};

/////////////////////////////////////

const Swap = () => {
    const { BigInt } = window
    const acc = useSelector((state) => state.account.value);
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [formTokenA, setFormTokenA] = useState('');
    const [formTokenB, setFormTokenB] = useState('');
    const [loading, setLoading] = useState(false);
    const [tokenAAmountBalance, setTokenAAmountBalance] = useState(0);
    const [tokenBAmountBalance, setTokenBAmountBalance] = useState(0);

    const AddMaxAmount = () => {
        if (tokenAAmountBalance > 0) {
            if (tokenAAmountBalance != formTokenA) {
                handleInputChangeTokenA(tokenAAmountBalance);
                setFormTokenA(tokenAAmountBalance);
            }
        } else {
            setFormTokenA(0);
        }
    }


    const options1 = [
        { value: 'option1', label: 'USDC', address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359", imageSrc: USDC },
        { value: 'option2', label: 'DAI', address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063", imageSrc: dai },
        { value: 'option3', label: 'ETH', address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", imageSrc: etherium },
        { value: 'option4', label: 'DWC', address: "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F", imageSrc: DWCLogo },
        { value: 'option5', label: 'USDT', address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F", imageSrc: USDT },
        { value: 'option6', label: 'MATIC', address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", imageSrc: MATIC },
        { value: 'option7', label: 'RNDR', address: "0x61299774020dA444Af134c82fa83E3810b309991", imageSrc: RNDR },
        { value: 'option8', label: 'GRT', address: "0x5fe2B58c013d7601147DcdD68C143A77499f5531", imageSrc: null, imgIcon: 'GR' },
        { value: 'option9', label: 'AAVE', address: "0xD6DF932A45C0f255f85145f286eA0b292B21C90B", imageSrc: AAVE },
        { value: 'option10', label: 'GALA', address: "0x3C69d114664d48357d820Dbdd121a8071eAc99bf", imageSrc: null, imgIcon: 'GL' },
        { value: 'option11', label: 'MANA', address: "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4", imageSrc: MANA },
        { value: 'option12', label: 'WOO', address: "0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603", imageSrc: WOO },
        { value: 'option13', label: 'CRV', address: "0x172370d5Cd63279eFa6d502DAB29171933a610AF", imageSrc: CRV },
        { value: 'option14', label: 'OCEAN', address: "0x282d8efCe846A88B159800bd4130ad77443Fa1A1", imageSrc: OCEAN },
        { value: 'option15', label: 'OM', address: "0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea", imageSrc: OM },
        { value: 'option16', label: 'SUPER', address: "0xa1428174F516F527fafdD146b883bB4428682737", imageSrc: SUPER },
        { value: 'option17', label: 'ANKR', address: "0x101A023270368c0D50BFfb62780F4aFd4ea79C35", imageSrc: ANKR },
        { value: 'option18', label: 'TRAC', address: "0x4287F07CBE6954f9F0DecD91d0705C926d8d03A4", imageSrc: TRAC },
        { value: 'option19', label: 'MASK', address: "0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7", imageSrc: MASK },
        { value: 'option20', label: 'FRAX', address: "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89", imageSrc: FRAX },
        { value: 'option21', label: 'SUSHI', address: "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a", imageSrc: null, imgIcon: 'SU' },
        { value: 'option22', label: 'TEL', address: "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32", imageSrc: TEL },
        { value: 'option23', label: 'VANRY', address: "0x8DE5B80a0C1B02Fe4976851D030B36122dbb8624", imageSrc: null, imgIcon: 'VN' },
        { value: 'option24', label: 'TRB', address: "0xE3322702BEdaaEd36CdDAb233360B939775ae5f1", imageSrc: null, imgIcon: 'TR' },
        { value: 'option25', label: 'PHA', address: "0x6a3E7C3c6EF65Ee26975b12293cA1AAD7e1dAeD2", imageSrc: PHA },
        { value: 'option26', label: 'ELON', address: "0xE0339c80fFDE91F3e20494Df88d4206D86024cdF", imageSrc: ELON },
        { value: 'option27', label: 'GNS', address: "0xE5417Af564e4bFDA1c483642db72007871397896", imageSrc: GNS },
        { value: 'option28', label: 'STG', address: "0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590", imageSrc: null, imgIcon: 'ST' },
        { value: 'option29', label: 'GHST', address: "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7", imageSrc: GHST },
        { value: 'option30', label: 'ALI', address: "0xbFc70507384047Aa74c29Cdc8c5Cb88D0f7213AC", imageSrc: ALI },
        { value: 'option31', label: 'VCNT', address: "0x8a16D4bF8A0a716017e8D2262c4aC32927797a2F", imageSrc: null, imgIcon: 'VN' },
        { value: 'option32', label: 'FAR', address: "0x5F32AbeeBD3c2fac1E7459A27e1AE9f1C16ccccA", imageSrc: null, imgIcon: 'FA' },
        { value: 'option33', label: 'QUICK', address: "0xB5C064F955D8e7F38fE0460C556a72987494eE17", imageSrc: QUICK },
        { value: 'option34', label: 'ROUTE', address: "0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4", imageSrc: null, imgIcon: 'RT' },
        { value: 'option35', label: 'LIME', address: "0x7f67639Ffc8C93dD558d452b8920b28815638c44", imageSrc: null, imgIcon: 'LM' },
        { value: 'option36', label: 'VOLT', address: "0x7f792db54B0e580Cdc755178443f0430Cf799aCa", imageSrc: VOLT },
        { value: 'option37', label: 'FOX', address: "0x65A05DB8322701724c197AF82C9CaE41195B0aA8", imageSrc: null, imgIcon: 'FX' },
        { value: 'option38', label: 'KLIMA', address: "0x4e78011Ce80ee02d2c3e649Fb657E45898257815", imageSrc: KLIMA },
        { value: 'option39', label: 'METAL', address: "0x200C234721b5e549c3693CCc93cF191f90dC2aF9", imageSrc: null, imgIcon: 'MT' },
        { value: 'option40', label: 'BCUT', address: "0x3fb83A9A2c4408909c058b0BfE5B4823f54fAfE2", imageSrc: null, imgIcon: 'BU' },////////////////////
        { value: 'option41', label: 'BIM', address: "0xe78649874bcDB7a9D1666E665F340723a0187482", imageSrc: null, imgIcon: 'BM' },
        { value: 'option42', label: 'RWA', address: "0x36FE11B6d5c9421F68d235694Fe192B35e803903", imageSrc: null, imgIcon: 'RA' },
        { value: 'option43', label: 'PUSH', address: "0x58001cC1A9E17A20935079aB40B1B8f4Fc19EFd1", imageSrc: PUSH },
        { value: 'option44', label: 'PROS', address: "0x6109cB051c5C64093830121ed76272aB04bBDD7C", imageSrc: null, imgIcon: 'PR' },
        { value: 'option45', label: 'CUBE', address: "0x276C9cbaa4BDf57d7109a41e67BD09699536FA3d", imageSrc: null, imgIcon: 'CB' },
        { value: 'option46', label: 'MEE', address: "0xEB7eaB87837f4Dad1bb80856db9E4506Fc441f3D", imageSrc: MEE },
        { value: 'option47', label: 'ADF', address: "0x6BD10299f4f1d31b3489Dc369eA958712d27c81b", imageSrc: null, imgIcon: 'AF' },
        { value: 'option48', label: 'VEXT', address: "0x27842334C55c01DDFE81Bf687425F906816c5141", imageSrc: null, imgIcon: 'VT' },
        { value: 'option49', label: 'VCHF', address: "0xCdB3867935247049e87c38eA270edD305D84c9AE", imageSrc: null, imgIcon: 'VH' },
        { value: 'option50', label: 'SWCH', address: "0x3ce1327867077B551ae9A6987bF10C9fd08edCE1", imageSrc: SWCH },
        { value: 'option51', label: 'MAHA', address: "0xEB99748e91AfCA94a6289db3b02E7ef4a8f0A22d", imageSrc: null, imgIcon: 'MH' },
        { value: 'option52', label: 'UFI', address: "0x3c205C8B3e02421Da82064646788c82f7bd753B9", imageSrc: UFI },
        { value: 'option53', label: 'NEX', address: "0xA486c6BC102f409180cCB8a94ba045D39f8fc7cB", imageSrc: null, imgIcon: 'NX' },
        { value: 'option54', label: 'CARR', address: "0x9b765735C82BB00085e9DBF194F20E3Fa754258E", imageSrc: null, imgIcon: 'CR' },
        { value: 'option55', label: 'PBR', address: "0x0D6ae2a429df13e44A07Cd2969E085e4833f64A0", imageSrc: PBR },
        { value: 'option56', label: 'XED', address: "0x2fe8733dcb25BFbbA79292294347415417510067", imageSrc: XED },
        { value: 'option57', label: 'TSUGT', address: "0x70e29b7E036B14D496431b77e0B6EB0008be6165", imageSrc: null, imgIcon: 'TU' },
        { value: 'option58', label: 'CTI', address: "0x03C2F6808502fFD4aB2787aD1A98eC13dbD5718b", imageSrc: null, imgIcon: 'CT' },
        { value: 'option59', label: 'NHT', address: "0x84342e932797FC62814189f01F0Fb05F52519708", imageSrc: null, imgIcon: 'NT' },
        { value: 'option60', label: 'KOM', address: "0xC004e2318722EA2b15499D6375905d75Ee5390B8", imageSrc: KOM },
        { value: 'option61', label: 'DSLA', address: "0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639", imageSrc: DSLA },
        { value: 'option62', label: 'DAFI', address: "0x638Df98Ad8069a15569dA5a6b01181804c47e34c", imageSrc: null, imgIcon: 'DF' },
        { value: 'option63', label: 'CONE', address: "0xbA777aE3a3C91fCD83EF85bfe65410592Bdd0f7c", imageSrc: CONE },
    ];
    const options2 = [
        { value: 'option1', label: 'USDC', address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359", imageSrc: USDC },
        { value: 'option2', label: 'DAI', address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063", imageSrc: dai },
        { value: 'option3', label: 'ETH', address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", imageSrc: etherium },
        { value: 'option4', label: 'DWC', address: "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F", imageSrc: DWCLogo },
        { value: 'option5', label: 'USDT', address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F", imageSrc: USDT },
        { value: 'option6', label: 'MATIC', address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", imageSrc: MATIC },
        { value: 'option7', label: 'RNDR', address: "0x61299774020dA444Af134c82fa83E3810b309991", imageSrc: RNDR },
        { value: 'option8', label: 'GRT', address: "0x5fe2B58c013d7601147DcdD68C143A77499f5531", imageSrc: null, imgIcon: 'GR' },
        { value: 'option9', label: 'AAVE', address: "0xD6DF932A45C0f255f85145f286eA0b292B21C90B", imageSrc: AAVE },
        { value: 'option10', label: 'GALA', address: "0x3C69d114664d48357d820Dbdd121a8071eAc99bf", imageSrc: null, imgIcon: 'GL' },
        { value: 'option11', label: 'MANA', address: "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4", imageSrc: MANA },
        { value: 'option12', label: 'WOO', address: "0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603", imageSrc: WOO },
        { value: 'option13', label: 'CRV', address: "0x172370d5Cd63279eFa6d502DAB29171933a610AF", imageSrc: CRV },
        { value: 'option14', label: 'OCEAN', address: "0x282d8efCe846A88B159800bd4130ad77443Fa1A1", imageSrc: OCEAN },
        { value: 'option15', label: 'OM', address: "0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea", imageSrc: OM },
        { value: 'option16', label: 'SUPER', address: "0xa1428174F516F527fafdD146b883bB4428682737", imageSrc: SUPER },
        { value: 'option17', label: 'ANKR', address: "0x101A023270368c0D50BFfb62780F4aFd4ea79C35", imageSrc: ANKR },
        { value: 'option18', label: 'TRAC', address: "0x4287F07CBE6954f9F0DecD91d0705C926d8d03A4", imageSrc: TRAC },
        { value: 'option19', label: 'MASK', address: "0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7", imageSrc: MASK },
        { value: 'option20', label: 'FRAX', address: "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89", imageSrc: FRAX },
        { value: 'option21', label: 'SUSHI', address: "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a", imageSrc: null, imgIcon: 'SU' },
        { value: 'option22', label: 'TEL', address: "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32", imageSrc: TEL },
        { value: 'option23', label: 'VANRY', address: "0x8DE5B80a0C1B02Fe4976851D030B36122dbb8624", imageSrc: null, imgIcon: 'VN' },
        { value: 'option24', label: 'TRB', address: "0xE3322702BEdaaEd36CdDAb233360B939775ae5f1", imageSrc: null, imgIcon: 'TR' },
        { value: 'option25', label: 'PHA', address: "0x6a3E7C3c6EF65Ee26975b12293cA1AAD7e1dAeD2", imageSrc: PHA },
        { value: 'option26', label: 'ELON', address: "0xE0339c80fFDE91F3e20494Df88d4206D86024cdF", imageSrc: ELON },
        { value: 'option27', label: 'GNS', address: "0xE5417Af564e4bFDA1c483642db72007871397896", imageSrc: GNS },
        { value: 'option28', label: 'STG', address: "0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590", imageSrc: null, imgIcon: 'ST' },
        { value: 'option29', label: 'GHST', address: "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7", imageSrc: GHST },
        { value: 'option30', label: 'ALI', address: "0xbFc70507384047Aa74c29Cdc8c5Cb88D0f7213AC", imageSrc: ALI },
        { value: 'option31', label: 'VCNT', address: "0x8a16D4bF8A0a716017e8D2262c4aC32927797a2F", imageSrc: null, imgIcon: 'VN' },
        { value: 'option32', label: 'FAR', address: "0x5F32AbeeBD3c2fac1E7459A27e1AE9f1C16ccccA", imageSrc: null, imgIcon: 'FA' },
        { value: 'option33', label: 'QUICK', address: "0xB5C064F955D8e7F38fE0460C556a72987494eE17", imageSrc: QUICK },
        { value: 'option34', label: 'ROUTE', address: "0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4", imageSrc: null, imgIcon: 'RT' },
        { value: 'option35', label: 'LIME', address: "0x7f67639Ffc8C93dD558d452b8920b28815638c44", imageSrc: null, imgIcon: 'LM' },
        { value: 'option36', label: 'VOLT', address: "0x7f792db54B0e580Cdc755178443f0430Cf799aCa", imageSrc: VOLT },
        { value: 'option37', label: 'FOX', address: "0x65A05DB8322701724c197AF82C9CaE41195B0aA8", imageSrc: null, imgIcon: 'FX' },
        { value: 'option38', label: 'KLIMA', address: "0x4e78011Ce80ee02d2c3e649Fb657E45898257815", imageSrc: KLIMA },
        { value: 'option39', label: 'METAL', address: "0x200C234721b5e549c3693CCc93cF191f90dC2aF9", imageSrc: null, imgIcon: 'MT' },
        { value: 'option40', label: 'BCUT', address: "0x3fb83A9A2c4408909c058b0BfE5B4823f54fAfE2", imageSrc: null, imgIcon: 'BU' },
        { value: 'option41', label: 'BIM', address: "0xe78649874bcDB7a9D1666E665F340723a0187482", imageSrc: null, imgIcon: 'BM' },
        { value: 'option42', label: 'RWA', address: "0x36FE11B6d5c9421F68d235694Fe192B35e803903", imageSrc: null, imgIcon: 'RA' },
        { value: 'option43', label: 'PUSH', address: "0x58001cC1A9E17A20935079aB40B1B8f4Fc19EFd1", imageSrc: PUSH },
        { value: 'option44', label: 'PROS', address: "0x6109cB051c5C64093830121ed76272aB04bBDD7C", imageSrc: null, imgIcon: 'PR' },
        { value: 'option45', label: 'CUBE', address: "0x276C9cbaa4BDf57d7109a41e67BD09699536FA3d", imageSrc: null, imgIcon: 'CB' },
        { value: 'option46', label: 'MEE', address: "0xEB7eaB87837f4Dad1bb80856db9E4506Fc441f3D", imageSrc: MEE },
        { value: 'option47', label: 'ADF', address: "0x6BD10299f4f1d31b3489Dc369eA958712d27c81b", imageSrc: null, imgIcon: 'AF' },
        { value: 'option48', label: 'VEXT', address: "0x27842334C55c01DDFE81Bf687425F906816c5141", imageSrc: null, imgIcon: 'VT' },
        { value: 'option49', label: 'VCHF', address: "0xCdB3867935247049e87c38eA270edD305D84c9AE", imageSrc: null, imgIcon: 'VH' },
        { value: 'option50', label: 'SWCH', address: "0x3ce1327867077B551ae9A6987bF10C9fd08edCE1", imageSrc: SWCH },
        { value: 'option51', label: 'MAHA', address: "0xEB99748e91AfCA94a6289db3b02E7ef4a8f0A22d", imageSrc: null, imgIcon: 'MH' },
        { value: 'option52', label: 'UFI', address: "0x3c205C8B3e02421Da82064646788c82f7bd753B9", imageSrc: UFI },
        { value: 'option53', label: 'NEX', address: "0xA486c6BC102f409180cCB8a94ba045D39f8fc7cB", imageSrc: null, imgIcon: 'NX' },
        { value: 'option54', label: 'CARR', address: "0x9b765735C82BB00085e9DBF194F20E3Fa754258E", imageSrc: null, imgIcon: 'CR' },
        { value: 'option55', label: 'PBR', address: "0x0D6ae2a429df13e44A07Cd2969E085e4833f64A0", imageSrc: PBR },
        { value: 'option56', label: 'XED', address: "0x2fe8733dcb25BFbbA79292294347415417510067", imageSrc: XED },
        { value: 'option57', label: 'TSUGT', address: "0x70e29b7E036B14D496431b77e0B6EB0008be6165", imageSrc: null, imgIcon: 'TU' },
        { value: 'option58', label: 'CTI', address: "0x03C2F6808502fFD4aB2787aD1A98eC13dbD5718b", imageSrc: null, imgIcon: 'CT' },
        { value: 'option59', label: 'NHT', address: "0x84342e932797FC62814189f01F0Fb05F52519708", imageSrc: null, imgIcon: 'NT' },
        { value: 'option60', label: 'KOM', address: "0xC004e2318722EA2b15499D6375905d75Ee5390B8", imageSrc: KOM },
        { value: 'option61', label: 'DSLA', address: "0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639", imageSrc: DSLA },
        { value: 'option62', label: 'DAFI', address: "0x638Df98Ad8069a15569dA5a6b01181804c47e34c", imageSrc: null, imgIcon: 'DF' },
        { value: 'option63', label: 'CONE', address: "0xbA777aE3a3C91fCD83EF85bfe65410592Bdd0f7c", imageSrc: CONE },




    ];
    
    async function FetchTokenBalanceA(tokenAddress) {
        try {
            console.log("calling", tokenAddress)
            if (acc != null) {
                let tempTokenBalance = await GetTokenBalance(tokenAddress, acc);
                if (tokenAddress == selectedOption2?.address) {
                    setTokenAAmountBalance(parseFloat(tempTokenBalance).toFixed(8));
                    setTokenBAmountBalance(parseFloat(tempTokenBalance).toFixed(8));
                }
                setTokenAAmountBalance(parseFloat(tempTokenBalance).toFixed(8));
            }
        } catch (e) {
            console.log(e)
        }
    }
    async function FetchTokenBalanceB(tokenAddress) {
        try {
            console.log("calling", tokenAddress);
            if (acc != null) {
                let tempTokenBalance = await GetTokenBalance(tokenAddress, acc);
                if (tokenAddress == selectedOption1?.address) {
                    setTokenAAmountBalance(parseFloat(tempTokenBalance).toFixed(8));
                    setTokenBAmountBalance(parseFloat(tempTokenBalance).toFixed(8));
                }
                setTokenBAmountBalance(parseFloat(tempTokenBalance).toFixed(8))
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleOptionClick1 = (option) => {
        FetchTokenBalanceA(option?.address)
        setFormTokenA("");
        setFormTokenB("");
        setSelectedOption1(option);
        setIsOpen1(false);
    };
    const handleOptionClick2 = (option) => {
        setFormTokenA("");
        setFormTokenB("");
        FetchTokenBalanceB(option?.address)
        setSelectedOption2(option);
        setIsOpen2(false);
    };

    const handleDocumentClick = (event) => {
        if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen1(false);
        }
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsOpen2(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
   
    useEffect(() => {
        setSelectedOption1(options1[1]);
        setSelectedOption2(options2[0]);
        if (acc?.length > 0) {
            FetchTokenBalanceA(options1[1]?.address);
            FetchTokenBalanceB(options2[0]?.address);
        }
    }, [acc]);
    const handleInputChangeTokenA = (value) => {
        setFormTokenA(value);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(
            setTimeout(async () => {
                if (parseFloat(value) > 0) {
                    try {
                        setLoading(true)
                        let convertRate = await GetConversionRate(selectedOption1?.address, selectedOption2?.address, value);
                        console.log("working", convertRate);
                        setFormTokenB(parseFloat(convertRate));
                    } catch (e) {
                        console.log("first", e)
                    } finally {
                        setLoading(false)
                    }
                } else {
                    setFormTokenB(0);
                    // setCheckSponsorExist([])
                }
            }, 500)
        );
    };
    async function trade() {
        console.log(selectedOption1);
        console.log(selectedOption2);

        if (parseFloat(formTokenA) > parseFloat(tokenAAmountBalance)) {
            toastFailed("Insufficient Fund")
            return 0;
        }

        let incAllow = await increaseAllowance();
        if (incAllow) {
            setLoading(true);
            try {
                const { ethereum } = window;
                if (ethereum) {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner();
                    const contractInstance = new ethers.Contract(
                        ContractDetails.Contract,
                        ContractDetails.ContractABI,
                        signer
                    );
                    let decimal = await GetTokenDecimal(selectedOption1?.address);
                    console.log("BigInt(formTokenA * decimal)", BigInt(formTokenA * decimal))
                    let inc = await contractInstance.Swap(
                        selectedOption1?.address,
                        selectedOption2?.address,
                        BigInt(formTokenA * decimal),
                    );
                    await inc.wait();
                    toastSuccess("Transaction Successful");
                    setLoading(false);

                }
            } catch (error) {
                Data.isDebug && console.log("error12", error);
                toastFailed("Something went wrong")
                setLoading(false);
            }
        }
    }
    async function increaseAllowance() {
        if (!formTokenA > 0) {
            toastFailed("Invalid amount")
            return false;
        }
        try {
            const { ethereum } = window;
            if (ethereum) {
                setLoading(true);
                const chain = await GetChainId();
                if (chain) {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner();
                    const contractInstance = new ethers.Contract(
                        selectedOption1?.address,
                        ContractDetails.USDTABI,
                        signer
                    );
                    let decimal = await GetTokenDecimal(selectedOption1?.address);
                    let inc = await contractInstance.increaseAllowance(
                        ContractDetails.Contract,
                        BigInt(formTokenA * decimal),
                    );
                    await inc.wait();
                    return true;
                } else {
                    setLoading(false);
                    return false;
                }
            } else {
                toastFailed("Please check your wallet");
                setLoading(false);
                return false;
            }
        } catch (error) {
            Data.isDebug && console.log("error12", error);
            toastFailed("Something went wrong");
            setLoading(false);
            return false;
        }
    }

    const SwapFields = () => {
        let temp = selectedOption1;
        setSelectedOption1(selectedOption2)
        setSelectedOption2(temp)

        let tempBalance = tokenAAmountBalance;
        let tokenAValue = formTokenA;
        setTokenAAmountBalance(tokenBAmountBalance);
        setTokenBAmountBalance(tempBalance);
        setFormTokenA("");
        setFormTokenB("");
    }


    return (
        <section className="swapPage">
            {/* {`
                loading && <Loader />
            } */}
            <img src={loops} id='swapLoops' alt="Loops" />
            <Container style={{ position: "relative", zIndex: "2" }}>
                <Header type="swap" />
                <h1 id='swapTopHeading'>Lightning AI<br />DEX</h1>
                <section className="swapExchangeSection">
                    {/* <div className="swapShaow1"></div> */}
                    <div className="swapShaow2"></div>
                    <div style={{ zIndex: "1", position: "relative" }}>

                        <div className="swapExchangeSection-settingContainer">
                            <div className='swapBtnRow'>
                                <div>
                                    <button className='sawpBtn'>
                                        <AiOutlineRetweet size={'30px'} />
                                        Swap
                                    </button >
                                </div>
                                <div className='promodeDiv' >
                                    <div>
                                        <span>PRO-MODE</span>
                                        <ToggleSwitch />
                                    </div>
                                    <div>
                                        <span>AUTO SLIPPAGE</span>
                                        <VscSettingsGear color='#fff' />
                                    </div>
                                </div>
                            </div>
                            <div className='versionBtnRow'>
                                <button className='versionBtn'>
                                    <FaCircleArrowDown size={'30px'} />
                                    Version
                                </button>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <p className='m-0' style={{ color: "grey", fontSize: "12px" }}>Coming Soon...</p>
                                    <button className='CrosschainBtn'>
                                        <img src={Crosschain} alt="" />
                                        Crosschain
                                    </button>
                                </div>
                            </div>
                        </div>


                        {/* <h1>exchange</h1>

                        <p></p> */}
                        <label htmlFor="">You Pay</label>
                        <div className="swapExchangeContainer">
                            <div className='swapExchangeContainerUp'  >
                                <span>From:</span>
                                <div className='swapExchangeDiv'>
                                    <div className='swapExchangeLeft'>
                                        <Dropdown
                                            options={options1}
                                            selectedOption={selectedOption1}
                                            setSelectedOption={setSelectedOption1}
                                            isOpen={isOpen1}
                                            setIsOpen={setIsOpen1}
                                            dropdownRef={dropdownRef1}
                                            handleOptionClick={handleOptionClick1}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        {/* <span className='InputReplace'>1.2032</span> */}
                                        <input type="number" placeholder='0' min={0} onChange={(e) => handleInputChangeTokenA(e.target.value)} value={formTokenA} />
                                        <button className='maxBtn' onClick={() => AddMaxAmount()}>MAX</button>
                                        {/* <i>
                                            <MdModeEdit fontSize={'24px'} />
                                        </i> */}
                                    </div>
                                </div>
                                <span>Balance: {tokenAAmountBalance}</span>
                            </div>
                            <div id="swapIcon" className={loading == true ? "SwapIconLoading" : ""}>
                                <img src={swapIcon} alt="Swap Icon" sizes='40px' onClick={SwapFields} />
                            </div>

                            <div className='swapExchangeContainerUp'  >
                                <span>To (estimate):</span>

                                <div className='swapExchangeDiv'>

                                    <div className='swapExchangeLeft'>
                                        <Dropdown
                                            options={options2}
                                            selectedOption={selectedOption2}
                                            setSelectedOption={setSelectedOption2}
                                            isOpen={isOpen2}
                                            setIsOpen={setIsOpen2}
                                            dropdownRef={dropdownRef2}
                                            handleOptionClick={handleOptionClick2}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        {/* <span className='InputReplace'>1.8532</span> */}

                                        <input type="number" placeholder='0' min={0} readOnly={true} value={formTokenB} />
                                        <button className='maxBtn' >MAX</button>
                                        {/* <i>
                                            <MdModeEdit fontSize={'24px'} />
                                        </i> */}
                                    </div>
                                </div>
                                <span>Balance: {tokenBAmountBalance}</span>
                            </div>
                            {/* <div className='approxRow'>
                                <div><span>Price:</span></div>
                                <div>
                                    <span>1 ETH=0.8532USDT</span>
                                    <button className='maxBtn approxBtn'>Approx</button>
                                </div>
                            </div> */}

                            <button className='swapConnectButton' onClick={trade}>Swap</button>


                        </div>

                    </div>
                </section>

                <p style={{ textAlign: "center", fontFamily: "Oxanium", fontWeight: "400", fontSize: "16px", lineHeight: "32px", textAlign: "center", opacity: "0.6", color: "white", marginTop: "20px" }}>The largest blockchain marketplace. Buy and sell crypto <br /> on Ethereum and 7+ other chains.</p>

                <h1 className='swapSubHeading' style={{ marginTop: "50px", marginBottom: "50px" }}>Go direct to DeFi</h1>

                <Row>
                    {[...Array(1)].map((_, index) => (
                        <Col lg="6" className='mb-4' key={index}>
                            <div className="servicesDiv">
                                <div className='servicesDivTopHeading'>
                                    <img src={webApp}></img>
                                    <h1>web app</h1>
                                </div>
                                <p>Swapping made simple. Access thousands of tokens on 8+ chains.</p>
                                <div>
                                    <ServiceCurrencyPrices />
                                    <ServiceCurrencyPrices />
                                    <ServiceCurrencyPrices />
                                    <ServiceCurrencyPrices />
                                </div>
                            </div>
                        </Col>
                    ))}
                    {[...Array(1)].map((_, index) => (
                        <Col lg="6" className='mb-4' key={index}>
                            <div className="servicesDiv">
                                <div className='servicesDivTopHeading'>
                                    <img src={webApp}></img>
                                    <h1>Transaction History</h1>
                                </div>
                                {/* <p>Swapping made simple. Access thousands of tokens on 8+ chains.</p> */}
                                <div>
                                    <TransactionTable />
                                </div>
                            </div>
                        </Col>
                    ))}
                    {[...Array(1)].map((_, index) => (
                        <Col lg="12" className='mb-4' key={index}>
                            <div className="servicesDiv">
                                <div className='servicesDivTopHeading'>
                                    <img src={devDocs}></img>
                                    <h1>Marketing Values</h1>
                                </div>
                                <p>Swapping made simple. Access thousands of tokens on 8+ chains.</p>
                                <div>
                                    <MarketingValue />

                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <section className='swap-section-3'>
                    <Row className='swap-section-3-mainContainer'>
                        <Col md='6'>
                            <h1>Trusted By Millions</h1>
                            <span>Uniswap products are powered by the Uniswap Protocol. The protocol is the largest onchain marketplace, with billions of dollars in weekly volume across thousands of tokens on Ethereum and 7+ additional chains.</span>
                            <button>Learn More
                                <i><FaArrowRight /></i>
                            </button>
                        </Col>
                        <Col md='6' className='swap-section-3-LeftDiv'>
                            <div className='swap-section-3-col-2-mainContainer'>
                                <Row>
                                    <Col xs='6'><div className='swap-section-3-col-2-div-1'>
                                        <h2>$2.0T</h2>
                                        <span>All time volume</span>
                                    </div> </Col>
                                    <Col xs='6'><div className='swap-section-3-col-2-div-2'>
                                        <h2>$2.0T</h2>
                                        <span>All time volume</span>
                                    </div> </Col>
                                </Row>
                                <Row>
                                    <Col xs='6'><div className='swap-section-3-col-2-div-3'>
                                        <h2>$2.0T</h2>
                                        <span>All time volume</span>
                                    </div> </Col>
                                    <Col xs='6'><div className='swap-section-3-col-2-div-4'>
                                        <h2>$2.0T</h2>
                                        <span>All time volume</span>
                                    </div> </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>
                <Footer />
            </Container >
        </section >
    );
};

export default Swap;





