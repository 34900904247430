import React from 'react'
import { Row, Col, Container, Accordion } from 'react-bootstrap'
import Header from '../../Components/Header/Header'
import Wave from './Images/wave.svg'
import { FaEnvelope, FaSearch } from 'react-icons/fa'
import Gear from './Images/Gear.png'
import Footer from '../../Components/Footer/Footer'
import './FAQ.css'
export default function FAQ() {

    const FaqOptions = () => {
        return (
            <div className='FaqOptions'>
                {/* <div className='FaqOptions-shadow'> dfg</div> */}
                <div className='FaqOptionsLeft'>
                    <img src={Gear} alt="" />
                </div>
                <div className='FaqOptionsRight'>
                    <h4>Getting Started</h4>
                    <span>Getting Started fast with installation and theme setup instrustions.</span>
                </div>
            </div>
        );
    };
    const FaqAccordion = () => {
        return (
            <Accordion className='accordianContainer'>
                <Accordion.Item >
                    <Accordion.Header>Do you provide customer support?</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, sapiente veniam natus modi non culpa maiores corrupti facere deleniti accusamus voluptas illo! Blanditiis culpa aspernatur excepturi vitae itaque suscipit laboriosam harum dolorum eius id fugit, quae perferendis laudantium vel consequatur reiciendis asperiores sapiente alias voluptatibus eum cum est molestias praesentium!
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };


    return (
        <Container className='faq-mainContainer'>
            <Header />
            <section className='faq-section-1'>
                <div className='faq-section-1-mainContainer'>
                    <div className='faq-section-1-upperContainer'>
                        <h2>How can we help you?</h2>
                        <span>Search for an answers</span>
                    </div>
                    <div className='faq-section-1-lowerContainer'>
                        <i> <FaSearch className="searchIcon" /></i>
                        <input placeholder="Search" />
                    </div>
                </div>

            </section>
            <section className='faq-section-2'>
                <div>
                    <img src={Wave} alt="" />
                </div>
                <div className='faq-section-2-rowContainer'>
                 <Row>
                 {[...Array(6)].map((_, index) => (
                       <Col md="6" key={index}><FaqOptions /></Col>
                    ))}
                 </Row>
                 
                 
                 
                    {/* <Row>
                        <Col md="6"><FaqOptions /></Col>
                        <Col md="6"><FaqOptions /></Col>
                    </Row>
                    <Row>
                        <Col md="6"><FaqOptions /></Col>
                        <Col md="6"><FaqOptions /></Col>
                    </Row>
                    <Row>
                        <Col md="6"><FaqOptions /></Col>
                        <Col md="6"><FaqOptions /></Col>
                    </Row> */}
                </div>
            </section>
            <section className='faq-section-3'>
                <div className='faq-section-3-mainContainer'>
                    <h3>Frequently asked Questions</h3>
                    <FaqAccordion />
                    <FaqAccordion />
                    <FaqAccordion />
                    <FaqAccordion />
                    <FaqAccordion />
                    <FaqAccordion />
                </div>
            </section>
            <section className='faq-section-4'>

                <div className="faq-section-4-mainContainer">
                    <div className="faq-section-4-shadow-1"></div>
                    <div className="faq-section-4-shadow-2"></div>
                    <h3>Didn't find an answer?</h3>
                    <span>Our team is just an email away and ready to answare your questions.</span>
                    <p>E-Mail</p>
                    <div>
                        <div>
                            <i><FaEnvelope fontSize={"28px"} color='#000' /></i>
                            <input type="email" placeholder='sales@murdefi.com' />
                        </div>

                        <button>Contact US</button>
                    </div>
                </div>
            </section>
            <section className='faq-section-5'>
                <Footer />
            </section>
        </Container>
    )
}
