import { ethers } from 'ethers';
import ContractDetails from '../Contracts/ContractDetails';
import { GetTokenDecimal } from './GetTokenDecimal';
import { Data, toastFailed } from './Data';

// Replace this with your preferred Polygon RPC provider
const POLYGON_RPC_URL = 'https://polygon-rpc.com'; // Official Polygon RPC URL

const provider = new ethers.providers.JsonRpcProvider(POLYGON_RPC_URL);
export async function GetConversionRate(tokenA, tokenB, amount) {
    console.log("tokenA", tokenA);
    console.log("tokenB", tokenB);
    console.log("amount", amount);
    const { BigInt } = window;

    try {
        const contractInstance = new ethers.Contract(
            ContractDetails.Contract,
            ContractDetails.ContractABI,
            provider
        );

        let tokenDecimalA = await GetTokenDecimal(tokenA);
        console.log("tokenDecimalAtokenDecimalA",tokenDecimalA)
        // Convert amount to an integer
        let amountbig = BigInt(amount*tokenDecimalA); 
        console.log("amountbig",amountbig)
        let amountWithDecimals = 0// Use BigInt literals

        let amt = await contractInstance.GetPrice(tokenA, tokenB, amountbig);
        console.log("amt", parseFloat(amt));

        let tokenDecimalB = await GetTokenDecimal(tokenB);
        Data.isDebug && console.log("tokenDecimal", tokenDecimalB);
        Data.isDebug && console.log("amt/tokenDecimal", parseFloat(amt));

        return parseFloat(amt /tokenDecimalB);
    } catch (error) {
        Data.isDebug && console.log("error12", error);
        toastFailed("Something went wrong");
        return null;
    }
}
