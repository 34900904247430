import React from 'react';
import './Toggle.css'; 

function ToggleSwitch() {
  return (
    <div>
      <label className="switch">
        <input type="checkbox" defaultChecked />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
