import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./HomePage.css";
import { FaArrowRight } from "react-icons/fa";
import { HiOutlineExternalLink } from "react-icons/hi";
import Letter from "./../../Images/sybil.png";
import GovPortal from "../../Images/Governance Portal.png";
import GovFourm from "../../Images/Governance Forum.png";
import { Col, Row, Container } from "react-bootstrap";
import "../../Fonts/CSS/fonts.css";

export default function HomePage() {
  return (
    <>
{/* <Container className="homePage-bodyContainer">
<Header/>
<section className="homepage-section-1">

</section>
</Container> */}


      {/* {/ <-------------------- section 1---------------------> /} */}
      <Container>
      <Header/>
      <section>
        <div className="section1-mainContainer">
          <div style={{ marginLeft: "30px", paddingTop: "100px" }}>
            <div>
              <p className="section1-mainContainer-MainText">
                DEFISWAP PROTOCOL
              </p>
              <p className="section1-mainContainer-Text">
                Swap, earn, and build on the leading decentralized crypto
                trading protocol.
              </p>
            </div>

            <div className="section1-butttonContainer">
              <button className="section1-button-1">Join Now</button>
              <button className="section1-button-2">Learn More</button>
            </div>
          </div>

          <div className="section1-numberCount-mainContainer">
            <div className="section1-numberCount-secondContainer">
              <div className="reviews">
                <p className="boldNumberCount">$1.8T+</p>
                <p className="numberCountDesc">Trade Volume</p>
              </div>
              <div className="reviews" >
                <p className="boldNumberCount">230M+</p>
                <p className="numberCountDesc">All The Trades</p>
              </div>
              <div className="reviews" >
                <p className="boldNumberCount">300+</p>
                <p className="numberCountDesc">Integration</p>
              </div>
              <div className="reviews" >
                <p className="boldNumberCount">4,400+</p>
                <p className="numberCountDesc">Community Delegates</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {/ -------------------- section 1 End --------------------> /}

      {/ <-------------------- section 2---------------------> /} */}
      <section className="section-2">
      
          <Row>
            <Col md="6">
              <div style={{ marginLeft: "30px" }}>
                <div className="section-2-left-mainContainer">
                  <div className="section-2-navigationContainer">
                    <div>
                      <p className="section-2-navigationText">
                        DEFIWAP ECOSYSTEM
                      </p>
                    </div>
                    <div>
                      <i className="section-2-arrowIcon">
                        <FaArrowRight />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="section-2-secondContainer">
                  <div className="section-2-secondContainer-textContainer">
                    <p className="section-2-secondContainer-textContainer-headText">
                      A growing network of DeFi Apps.
                    </p>
                    <p className="section-2-secondContainer-textContainer-descText">
                      Developers, traders, and liquidity providers participate
                      together in a financial marketplace that is open and
                      accessible to all.
                    </p>
                  </div>
                </div>
                <button className="section1-button-1">Explore All</button>
              </div>
            </Col>
            <Col md="6">
              <div className="">
                <img
                  src={require("../../Images/section2Img.png")}
                  alt=""
                  className="section-2-Img"
                ></img>
              </div>
            </Col>
          </Row>
      
      </section>
      {/* {/ -------------------- section 2 End --------------------> /}

      {/ <-------------------- section 3---------------------> /} */}
      <section className="section-3">
       
          <Row>
            <Col md="6">
              <div>
                <div className="section-3-navigationContainer">
                  <p className="section-2-navigationText">DEVELOPERS</p>
                  <i className="section-3-arrowIcon">
                    <FaArrowRight />
                  </i>
                </div>
                <div className="section-2-secondContainer">
                  <div className="section-2-secondContainer-textContainer">
                    <p className="section-2-secondContainer-textContainer-headText">
                      Superpowers for DeFi developers.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div>
                <p className="section-3-secondContainer-textContainer-descText">
                  Build Defi apps and tools on the largest crypto project on
                  Ethereum. Get started with quick start guides, protocol
                  documentation, a Javascript SDK, and fully open source code.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="section-3-leftBoxContainer">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <div
                    className="section-3-leftBoxContaine-2ArrowRoundIcon"
                    style={{
                      width: " 80px",
                      height: "80px",
                    }}
                  >
                    <img
                      src={require("../../Images/repeatIcon.png")}
                      alt=""
                      height={50}
                      width={50}
                    />
                  </div>
                </div>
                <div>
                  <p className="section-3-leftBoxContainer-headText">
                    DeFiswap
                  </p>
                  <div className="section-3-leftBoxContaine-buttonContainer">
                    <button className="section-3-leftboxContainer-button">
                      Documentation
                      <HiOutlineExternalLink />
                    </button>
                    <button className="section-3-leftboxContainer-button">
                      V3 Whitepaper
                      <HiOutlineExternalLink />
                    </button>
                    <button className="section-3-leftboxContainer-button">
                      Github
                      <HiOutlineExternalLink />
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="8">
              <div className="section-3-rightBoxContainer">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <img
                    src={require("../../Images/certificate.png")}
                    alt=""
                    className="section-3-leftBoxContaine-certificateImg"
                  ></img>
                </div>
                <div>
                  <p className="section-3-rightBoxContainer-headText">
                    Apply for funding from the Uniswap Grants Program
                  </p>
                  <p className="section-3-rightBoxContainer-descText">
                    Get paid to build the future of finance. Uniswap Governance
                    offers grant funding for people building apps, tools, and
                    activities on the Uniswap Protocol.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <button className="section-3-rightBoxContainer-button">
                    Learn More
                  </button>
                </div>
              </div>
            </Col>
            </Row>
      
      </section>
      {/* {/ -------------------- section 3 End --------------------> /}

      {/ <-------------------- section 4---------------------> /} */}
     
        <section className="section-4">
     
          <div className="section-4-mainContainer">
            <div
              style={{
                background:
                  "radial-gradient(50% 50% at 50% 50%, #03818E 0%, rgba(2, 11, 16, 0) 100%)",
              }}
            ></div>
            <div className="section-4-upperContainer">
              <p className="section-4-upperContainer-headText">
                Governed by the community.
              </p>
              <p className="section-4-upperContainer-descText">
                The Uniswap Protocol is managed by a global community of UNI
                token holders and delegates.
              </p>
            </div>
            <button className="section-4-upperContainer-button">
              Read More
            </button>
          </div>
          <div className="section-4-secondContainer">
            <div className="section-4-secondContainerGrid">
              {/* <div className="shadowOne"></div>
                        <div className="shadowTwo"></div> */}
              <div className="sybil">
                <div>
                  <div>
                    <div>
                      <img src={GovFourm} alt="Sybil" />
                    </div>
                    <p>Governance Forum</p>
                  </div>
                  <i style={{ rotate: "330deg", color: "#00E7FF" }}>
                    <FaArrowRight />
                  </i>
                </div>
                <p>
                  Participate by proposing upgrades and discussing the future of
                  the protoco with the Uniswap community.
                </p>
              </div>
            </div>
            <div className="section-4-secondContainerGrid">
              {/* <div className="shadowOne"></div>
                        <div className="shadowTwo"></div> */}

              <div className="sybil">
                <div>
                  <div>
                    <div>
                      <img src={Letter} alt="Sybil" />
                    </div>
                    <p>Sybil</p>
                  </div>
                  <i style={{ rotate: "330deg", color: "#00E7FF" }}>
                    <FaArrowRight />
                  </i>
                </div>
                <p>
                  Vote on offchain proposals with the Snapshot interface. Votes
                  are weighted by the number of UNI delegates.
                </p>
              </div>
            </div>
            <div className="section-4-secondContainerGrid">
              <div className="sybil">
                <div>
                  <div>
                    <div>
                      <img src={GovPortal} alt="Sybil" />
                    </div>
                    <p>Governance Portal</p>
                  </div>
                  <i style={{ rotate: "330deg", color: "#00E7FF" }}>
                    <FaArrowRight />
                  </i>
                </div>
                <p>
                  Vote on official Uniswap governance proposals and view past
                  proposals.
                </p>
              </div>
            </div>
          </div>
   

        </section>
      <Footer/>
      </Container>
      {/* {/ -------------------- section 4 End --------------------> /}

      {/ <-------------------- Footer Section ---------------------> /} */}
     
    




</>
  );
}