import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Header from '../../Components/Header/Header'
import rightArrow from './Images/rightArrow.png'
import discord from "./Images/discord.svg"
import twitter from "./Images/twitter.svg"
import defNews from "./Images/defNews.svg"
import insta from "./Images/insta.svg"
import fb from "./Images/fb.svg"
import tele from "./Images/tele.svg"
import Bull from "./Images/sec3Img1.svg"
import Game from "./Images/sec3Img2.svg"
import Token from "./Images/sec3Img3.svg"
import Layer from './Images/sec4BgLayer.svg'
import LockUp from './Images/DefiLockup.svg'
import BlockChain from './Images/Blockchain.svg'
import "./Community.css"
import wave from './Images/wave.svg'
import Footer from '../../Components/Footer/Footer'
import rotateArrow from './Images/rotateArrowIcon.svg'
export default function Community() {
    return (
        <Container className="BodyConatiner">
            <Header />
            <section className='community-section-1'>
                <div className='community-section-1-mainContainer'>
                    <div className="community-section-1-mainContainer-iconContainer">
                        <img src={require("./Images/fireIcon.png")} alt="" height="54px" width="54px" />
                    </div>
                    <div className="community-section-1-mainContainer-textContainer">
                        <p>The <span>DeFiswap</span> community is an ecosystem of users, developers, designers, and educators</p>
                    </div>
                </div>
            </section>
            <section className='community-section-2'>
                <img src={wave} alt="" />
                <div id="community-section-2-topDiv">
                    <div id="community-section-2-topDiv-upperDiv">
                        <span className='community-section-2-headText'>Join the conversation</span>
                        <span className='community-section-2-descText'>Uniswap's global and vibrant community drives the success of the Protocol. Join the conversation on Discord, Twitter, and Reddit to stay up to date on the latest community news.</span>
                        <span className='community-section-2-bottomText'>Share here, there, and everywhere.</span>
                        <img src={rightArrow} alt='' height="54px" width="54px" className='community-section-2-rightArrow' />
                    </div>
                </div>
                <div className="community-section-2-bottomDiv">
                    <Row className="community-section-2-bottomDiv-row-1">
                        <Col md="4" className="community-section-2-bottomDiv-row-1-col">
                            <div className="community-section-2-bottomDiv-mainContainer">
                                <div className="community-section-2-bottomDiv-upperDiv">
                                    <div>
                                    <div className='community-section-2-bottomDiv-iconDiv'><img src={discord} alt="" height="22px" width="22px" /></div>
                                    <div className='community-section-2-bottomDiv-headText' >Discord</div>
                                    </div>
                                    <div><img src={rotateArrow} alt="" /></div>
                                </div>
                                <div className="community-section-2-bottomDiv-textDiv">Ask questions and engage with the Uniswap Community</div>
                            </div>
                        </Col>
                        <Col md="4" className="community-section-2-bottomDiv-row-1-col">
                            <div className="community-section-2-bottomDiv-mainContainer">
                                <div className="community-section-2-bottomDiv-upperDiv">
                                    <div>
                                    <div className='community-section-2-bottomDiv-iconDiv'><img src={twitter} alt="" height="22px" width="22px" /></div>
                                    <div className='community-section-2-bottomDiv-headText' >Twitter</div>
                                    </div>
                                    <div><img src={rotateArrow} alt="" /></div>
                                </div>
                                <div className="community-section-2-bottomDiv-textDiv">Ask questions and engage with the Uniswap Community</div>
                            </div>
                        </Col>
                        <Col md="4" className="community-section-2-bottomDiv-row-1-col">
                            <div className="community-section-2-bottomDiv-mainContainer">
                                <div className="community-section-2-bottomDiv-upperDiv">
                                    <div>
                                    <div className='community-section-2-bottomDiv-iconDiv'><img src={defNews} alt="" height="22px" width="22px" /></div>
                                    <div className='community-section-2-bottomDiv-headText' >DeFi News</div>
                                    </div>
                                    <div><img src={rotateArrow} alt="" /></div>
                                </div>
                                <div className="community-section-2-bottomDiv-textDiv">Ask questions and engage with the Uniswap Community</div>
                            </div>
                        </Col>

                    </Row>
                    <Row className="community-section-2-bottomDiv-row-1">
                        <Col md="4" className="community-section-2-bottomDiv-row-1-col">
                            <div className="community-section-2-bottomDiv-mainContainer">
                                <div className="community-section-2-bottomDiv-upperDiv">
                                    <div>
                                    <div className='community-section-2-bottomDiv-iconDiv'><img src={insta} alt="" height="32px" width="32px" /></div>
                                    <div className='community-section-2-bottomDiv-headText' >Instagram</div>
                                    </div>
                                    <div><img src={rotateArrow} alt="" /></div>
                                </div>
                                <div className="community-section-2-bottomDiv-textDiv">Ask questions and engage with the Uniswap Community</div>
                            </div>
                        </Col>
                        <Col md="4" className="community-section-2-bottomDiv-row-1-col">
                            <div className="community-section-2-bottomDiv-mainContainer">
                                <div className="community-section-2-bottomDiv-upperDiv">
                                    <div>
                                    <div className='community-section-2-bottomDiv-iconDiv'><img src={fb} alt="" height="32px" width="32px" /></div>
                                    <div className='community-section-2-bottomDiv-headText' >Facebook</div>
                                    </div>
                                    <div><img src={rotateArrow} alt="" /></div>
                                </div>
                                <div className="community-section-2-bottomDiv-textDiv">Ask questions and engage with the Uniswap Community</div>
                            </div>
                        </Col>
                        <Col md="4" className="community-section-2-bottomDiv-row-1-col">
                            <div className="community-section-2-bottomDiv-mainContainer">
                                <div className="community-section-2-bottomDiv-upperDiv">
                                    <div>
                                    <div className='community-section-2-bottomDiv-iconDiv'><img src={tele} alt="" height="32px" width="32px" /></div>
                                    <div className='community-section-2-bottomDiv-headText' >Telegram</div>
                                    </div>
                                    <div><img src={rotateArrow} alt="" /></div>
                                </div>
                                <div className="community-section-2-bottomDiv-textDiv">Ask questions and engage with the Uniswap Community</div>
                            </div>
                        </Col>

                    </Row>


                </div>

            </section>
            <section className='community-section-3'>
                <div id="community-section-3-topDiv">
                    <div className="community-section-3-topDiv-headText">
                        Uniswap community members contribute and xpress themselves through mediums beyond finance
                    </div>
                    <div className="community-section-3-topDiv-descText">
                        We believe that serious play can lead to serious things.
                    </div>
                    <img src={rightArrow} alt='' height="54px" width="54px" className='community-section-3-rightArrow' />
                </div>
                <div className="community-section-3-bottomDiv">
                    <Row className="community-section-3-row">
                        <Col md="4" className="community-section-3-row-col">
                            <div className="community-section-3-mainContainer">
                                <div className="community-section-3-mainContainer-imgDiv">
                                    <div><img src={Bull} alt="" width="100%" height="70%" /></div>
                                </div>
                                <div className='community-section-3-mainContainer-headText' >Discord</div>
                                <div className="community-section-3-mainContainer-descText">Ask questions and engage with the Uniswap Community</div>
                                <button className='community-section-3-mainContainer-btn'>See More</button>
                            </div>
                        </Col>
                        <Col md="4" className="community-section-3-row-col">
                            <div className="community-section-3-mainContainer">
                                <div className="community-section-3-mainContainer-imgDiv">
                                    <div><img src={Game} alt="" width="100%" height="70%" /></div>
                                </div>
                                <div className='community-section-3-mainContainer-headText' >Discord</div>
                                <div className="community-section-3-mainContainer-descText">Ask questions and engage with the Uniswap Community</div>
                                <button className='community-section-3-mainContainer-btn'>See More</button>
                            </div>
                        </Col>
                        <Col md="4" className="community-section-3-row-col">
                            <div className="community-section-3-mainContainer">
                                <div className="community-section-3-mainContainer-imgDiv">
                                    <div><img src={Token} alt="" width="100%" height="70%" /></div>
                                </div>
                                <div className='community-section-3-mainContainer-headText' >Discord</div>
                                <div className="community-section-3-mainContainer-descText">Ask questions and engage with the Uniswap Community</div>
                                <button className='community-section-3-mainContainer-btn'>See More</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='community-section-4'>
                <img src={Layer} alt="" />
                <div id="community-section-4-topDiv">
                    <div id="community-section-4-topDiv-upperDiv">
                        <span className='community-section-4-headText'>Brand assets</span>
                        <span className='community-section-4-descText'>Download the logo and other brand assets here.</span>
                        <img src={rightArrow} alt='' height="54px" width="54px" className='community-section-4-rightArrow' />
                    </div>
                </div>
                <div className="community-section-4-bottomDiv">
                    <Row className="community-section-4-row">
                        <Col md="4" className="community-section-4-row-col">
                            <div className="community-section-4-mainContainer">
                                <div className="community-section-4-mainContainer-imgDiv">
                                    <div><img src={LockUp} alt="" width="100%" height="70%" /></div>
                                </div>
                                <div className='community-section-4-mainContainer-headText' >DeFiswap Lockup</div>
                                <div className="community-section-4-mainContainer-descText">Commonly used where the full DeFiswap branding needs to be displyed.</div>
                                <button className='community-section-4-mainContainer-btn'>Download</button>
                            </div>
                        </Col>
                        <Col md="4" className="community-section-4-row-col">
                            <div className="community-section-4-mainContainer">
                                <div className="community-section-4-mainContainer-imgDiv">
                                    <div><img src={BlockChain} alt="" width="100%" height="70%" /></div>
                                </div>
                                <div className='community-section-4-mainContainer-headText' >DeFiswap Lockup</div>
                                <div className="community-section-4-mainContainer-descText">Commonly used where the full DeFiswap branding needs to be displyed.</div>
                                <button className='community-section-4-mainContainer-btn'>Download</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='community-section-5'>
                <Footer />
            </section>
        </Container>
    )
}
