import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Swap from "./Pages/Swap/Swap";
import Ecosystem from "./Pages/Ecosystem/Ecosystem";
import Community from "./Pages/Community/Community";
import HomePage from "./Pages/HomePage/HomePage";
import "./App.css";
import Governance from "./Pages/Governance/Governance"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FAQ from "./Pages/FAQ/FAQ";
import Blogs from "./Pages/Blogs/Blogs";
import Home from "./Pages/HomePage/HomePage";
import FetchEvenTest from "./Pages/FetchEventstest";
const App = () => {
  return (
    <>
      <ToastContainer autoClose={4000} draggable={true} theme="colored" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/ecosystem" element={<Ecosystem />}></Route>
          <Route path="/community" element={<Community />}></Route>
          <Route path="/governance" element={<Governance />}></Route>
          <Route path="/swap" element={<Swap />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          <Route path="/test" element={<FetchEvenTest />}></Route>
          {/* <Route path="/home" element={<Home />}></Route> */}

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
