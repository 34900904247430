import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../Components/Header/Header'
import BitImg from "../../Images/bitcoin.svg"
import { FaEnvelope, FaEye, FaSearch } from 'react-icons/fa'
import Wave from './Images/BlogBgWave.svg'
import BlogImg from './Images/BlogImg.png'
import Calander from './Images/Calender.png'
import './Blogs.css'
import Footer from '../../Components/Footer/Footer'
export default function Blogs() {

    const Blog = () => {
        return (
            <div className='blogContainer'>
                {/* <div className='blogImgContainer'> */}
                <img src={BlogImg} alt="" width={"100%"} />
                <div>
                    <h5>
                        Defiswap Labs and Across propose standard for intents.
                    </h5>
                    <span>
                        A forum for governance-related discussion. Share proposals, provide feedback, and shape the future of the protocol with the Uniswap community.
                    </span>
                </div>
                <div>
                    <div className='blogContainer-line'></div>
                    <div className='blogContainer-dateContainer'>
                        <div>
                            <div className='blogContainer-line2'></div>
                            <img src={Calander} alt="" height='85%' />
                            <span>22/04/2024</span>
                        </div>
                        <div>
                            <i><FaEye color='#00FFFF' /></i>
                            <span>58k Veiws</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <Container className='blogs-mainContainer'>
            <Header />
            <section className='blogs-section-1'>
                <Row className="blogs-section-1-mainContainer" >
                    <Col md="8" className="blogs-section-1-mainContainer-leftDiv" >
                        <h5>Discover nice articles here...</h5>
                        <span>All The Articles And Contents Of The Site Have Been <span>Updated Today</span> And You Can Find <span>Your Articles</span> And Contents Quickly And Without Any Problems.</span>

                        <div className='blogs-section-1-lowerContainer'>
                            <i> <FaSearch className="searchIcon" /></i>
                            <input placeholder="Search" />
                        </div>

                    </Col>
                    <Col md="4" className="blogs-section-1-mainContainer-rightDiv" >
                        <img src={BitImg} alt="" />
                    </Col>
                </Row>
            </section>
            <section className='blogs-section-2'>
            </section>
            <section className='blogs-section-3'>
                <div className='blogs-section-3-BgImg'>
                    <img src={Wave} alt="" width={"100%"} />
                </div>
                <div className='blogs-section-3-floatdiv'>
                    <Row className='blogs-section-3-floatdiv-row'>
                        <Col md='4'><Blog /></Col>
                        <Col md='4'><Blog /></Col>
                        <Col md='4'><Blog /></Col>
                    </Row>
                    <Row className='blogs-section-3-floatdiv-row'>
                        <Col md='4'><Blog /></Col>
                        <Col md='4'><Blog /></Col>
                        <Col md='4'><Blog /></Col>
                    </Row>
                </div>
                <div>
                    {/* number selector( Centered Pagination)*/}
                </div>
            </section>
            <section className='blogs-section-4'>
                {/* <div className="blogs-section-4-mainContainer">

                </div> */}
                <div className="blogs-section-4-mainContainer">
                    <div className="blogs-section-4-shadow-1"></div>
                    <div className="blogs-section-4-shadow-2"></div>
                    <h3>Subscribe For New Content</h3>
                    <span>By Becoming A Member of Our Blog. You Have Access Articles And Content.</span>
                    <p>E-Mail</p>
                    <div>
                        <div>
                            <i><FaEnvelope fontSize={"28px"} color='#000' /></i>
                            <input type="email" placeholder='sales@murdefi.com' />
                        </div>

                        <button>Contact US</button>
                    </div>
                </div>

            </section>
            <section className='blogs-section-5'>
                <Footer/>
            </section>
        </Container>
    )
}
