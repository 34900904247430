import React from "react";
import Header from "../../Components/Header/Header";
import "./Governance.css";
import BitImg from "../../Images/bitcoin.svg"
import { Col, Container, Row } from "react-bootstrap";
import instaIcon from "./Images/discord 1.png"
import Wave from "./Images/Nete 2.svg"
import { FaArrowRight } from "react-icons/fa";
import ProcessBitCoin from "./Images/Process BitCoin.svg"
import Footer from "../../Components/Footer/Footer"
function Governance() {
  return (
    <Container className="governance-mainContainer">
      <Header />
      <section className="governance-section-1">
        <Row className="governance-section-1-mainContainer" >
          <Col md="8" className="governance-section-1-mainContainer-leftDiv" >
            <h5>The DeFiwap Protocol is a public good owned and governed by DWC token holders</h5>
            <span>The Uniswap token enables community ownership and active stewardship of the Protocol. UNI holders govern the Protocol through an on-chain governance process.</span>
            <div>
              <button className="governance-section-1-mainContainer-leftDiv-btn-1">Join Now</button>
              <button className="governance-section-1-mainContainer-leftDiv-btn-2">Read More</button>
            </div>
          </Col>
          <Col md="4" className="governance-section-1-mainContainer-rightDiv" >
            <img src={BitImg} alt="" />
          </Col>
        </Row>
      </section>

      <section className="governance-section-2">
        <div className="governance-section-2-mainContainer">

          <div className="governance-section-2-upperContainer">
            {/* <div className="governance-section-2-Sec2UpperBg">
              <img src={Sec2UpperBg} alt="" style={{ width: "100%" }} />
              </div> */}
            <h4>THE GOVERNANCE APPS</h4>
            <span>Share your opinions and shape the future of the protocol</span>

          </div>

          <div className="governance-section-2-lowerContainer">
            <div className="container">
              <div className="row">
                <div className="col-sm">
                  <div className="governance-section-2-lowerContainer-Col">
                    <div>
                      <div>
                        <div>
                          <img src={instaIcon} alt="" />
                        </div>
                        <p>Governance Forum</p>
                      </div>
                      <i style={{ rotate: "330deg", color: "#00E7FF", fontSize: "22px" }}>
                        <FaArrowRight />
                      </i>
                    </div>
                    <p>
                      A forum for governance-related discussion. Share proposals, provide feedback, and shape the future of the protocol with theUniswap community.
                    </p>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="governance-section-2-lowerContainer-Col">
                    <div>
                      <div>
                        <div>
                          <img src={instaIcon} alt="" />
                        </div>
                        <p>Governance Portal</p>
                      </div>
                      <i style={{ rotate: "330deg", color: "#00E7FF", fontSize: "22px" }}>
                        <FaArrowRight />
                      </i>
                    </div>
                    <p>
                      The official governance voting portal.
                      Review live governance proposals and
                      cast your vote on-chain.
                    </p>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="governance-section-2-lowerContainer-Col">
                    <div>
                      <div>
                        <div>
                          <img src={instaIcon} alt="" />
                        </div>
                        <p>Snapshot</p>
                      </div>
                      <i style={{ rotate: "330deg", color: "#00E7FF", fontSize: "22px" }}>
                        <FaArrowRight />
                      </i>
                    </div>
                    <p>
                      A simple off-chain voting interface for
                      community members to signal sentiment
                      during the early stages of a proposal’s
                      life cycle
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="governance-section-3">
        <div className="waveDiv">
          <img src={Wave} alt="" width="100%" />
        </div>
        <div className="sec3BgImgDiv">
          <div className="governance-section-3-floatContainer" >
            <div className="governance-section-3-floatContainer-shadow-1" ></div>
            <div className="governance-section-3-floatContainer-shadow-2"> </div>
            <div className="governance-section-3-floatContainer-headTxtDiv">
              <span>
                THE GOVERNANCE PROCESS
              </span>
            </div>
            <Row className="governance-section-3-row">
              <Col md='4'>
                <div className="governance-section-3-processList">
                  <li>Phase 1: Temperature Check</li>
                  <li>Phase 2: Consensus Check</li>
                  <li>Phase 3: Governance Proposal</li>
                </div>
              </Col>
              <Col md='4' className="governance-section-3-bitCoinImg">
                <img src={ProcessBitCoin} alt="" width={"60%"} />
              </Col>
              <Col md='4' className="governance-section-3-governanceProposal">
                <h3 >Governance Proposal</h3>
                <span>A Governance Proposal is the final step in the governance process. The proposal should incorporate feedback from the Consensus Check and is accompanied by executable on-chain code. In order to submit an on-chain Governance proposal, a delegate must have a minimum balance of 2m UNI. The voting period lasts 7 days and a majority vote with a 40m UNI yes-vote threshold wins.</span>
                <button>Read More</button>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      
      <section>
        <Footer />
      </section>
    </Container>
  );
}

export default Governance;
