import React from 'react'
import "./Footer.css"
import { TiSocialFacebook } from "react-icons/ti";
import { FiInstagram } from "react-icons/fi";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function Footer() {
    return (
        <footer className="bd-footer py-4 py-md-5 mt-5 bg-body-tertiary">
            <div className="container py-4 py-md-5 px-4 px-md-3 text-body-secondary">
                <div className="row" style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div className="col-lg-3 mb-3">
                        <div className='footer-Col-1'>
                            <div className='footer-Col-1-headContainer'>
                                <img src={require('../../Images/DeFiLogo.png')} alt="" />
                                <span className='footer-Col-headText'>
                                    DeFi Swap
                                </span>
                            </div>
                            <div className='footer-Col-descText-Container'>
                                <span>The best place for exchange your trading and money
                                </span>
                            </div>
                            <div className='footer-Col-1-socialMediaIcons'>
                                <TiSocialFacebook />
                                <FiInstagram />
                                <FaTwitter />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-2 offset-lg-1 mb-3">
                        <h5>Ecosystem</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2"><Link to="/" className='footerList'>Crypto</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>Our Article</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>News Update</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>Our Blogging</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>NewsLetter</Link></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2 mb-3">
                        <h5>Community</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2"><Link to="/" className='footerList'>Our story</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>New Consulting</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>Our Customers</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>Expert Support</Link></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2 mb-3">
                        <h5>Governance</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2"><Link to="/" className='footerList'>About Us</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>FAQ</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>Privacy & Policy</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>Help</Link></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2 mb-3">
                        <h5>Address</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2"><Link to="/" className='footerList'>info@defiworld.com </Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>602, Opal tower, Business Bay Dubai, UAE 500001.</Link></li>
                            <li className="mb-2"><Link to="/" className='footerList'>+971 44496592 </Link></li>
                        </ul>
                    </div>
                </div>

                <div className='footer-bottom-section'>
                    <p className='footer-bottom-section-text'>All right reseved Defiswap 2024</p>
                </div>



            </div>
        </footer>
    )
}
