import { ethers } from 'ethers';
import ContractDetails from '../Contracts/ContractDetails';
import { Data } from './Data';

// Replace this with your preferred Polygon RPC provider
const POLYGON_RPC_URL = 'https://polygon-rpc.com'; // Official Polygon RPC URL

const provider = new ethers.providers.JsonRpcProvider(POLYGON_RPC_URL);

export async function GetTokenBalance(tokenAddress, address) {
    console.log("Fetching token decimals...", tokenAddress);
    console.log("Fetching token decimals... address", address);

    try {
        const contractInstance = new ethers.Contract(
            tokenAddress,
            ContractDetails.USDTABI,
            provider
        );

        const decimals = await contractInstance.decimals();
        const decimalValue = 10 ** decimals;
        Data.isDebug && console.log("Token decimals:", decimals);
        Data.isDebug && console.log("111 Token decimals:", decimalValue);

        const balance = await contractInstance.balanceOf(address);
        const formattedBalance = balance / decimalValue;
        Data.isDebug && console.log("Formatted balance:", formattedBalance);

        return formattedBalance;
    } catch (error) {
        console.error("Error fetching token decimals:", error);
        return null;
    }
}
