import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Ecosystem.css";
import Header from "../../Components/Header/Header";
import { FaSearch } from "react-icons/fa";
import Sec2BgImg from "../Ecosystem/Images/Sec2BgImg.png"
import Sec2Icon1 from "../Ecosystem/Images/Sec2Icon1.png"
import Sec2Icon2 from "../Ecosystem/Images/Sec2Icon2.png"
import Sec2Icon3 from "../Ecosystem/Images/Sec2Icon3.png"
import Sec2Icon4 from "../Ecosystem/Images/Sec2Icon4.png"
import Footer from "../../Components/Footer/Footer"
import "../../Fonts/CSS/fonts.css";

export default function Ecosystem() {
  return (
    <Container className="ecosystemBodyContainer">
      <Header />
      <section className="ecosysten-section-1">
        <div className="section-1-topContainer">
          <div className="section-1-topContainer-upperContainer">
            <span>0% trading commision</span>
          </div>
          <div className="section-1-topContainer-middleContainer">
            <p>Join <span>the best </span>cryptocurrency<br/> exchange</p>
          </div>

          <div className="section-1-topContainer-lowerContainer">
            <span>
              Browse and search projects built on the DeFiswap Protocol.*
              Building something cool with DeFiswap? Submit your project.
            </span>
          </div>
        </div>
        <div className="section-1-bottomContainer">
          <i>
            <FaSearch className="searchIcon" />
          </i>
          <input placeholder="Search" />
        </div>
      </section>

      <section className="ecosystem-section-2">
        <Container className="ecosystem-section-2-Container">
          <Row className="ecosystem-section-2-row">
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon1} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon2} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon3} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon4} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="ecosystem-section-2-row">
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon1} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon2} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon3} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon4} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="ecosystem-section-2-row">
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon1} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon2} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon3} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon4} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="ecosystem-section-2-row">
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon1} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon2} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon3} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon4} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="ecosystem-section-2-row">
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon1} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon2} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon3} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="3" className="ecosystem-section-2-row-col">
              <div className="bgMainContainer">
                <div className="bgImgDiv">
                  <img src={Sec2BgImg} alt="" />
                </div>
                <div className="walletTextDiv" >
                  <p>Wallet</p>
                </div>
                <div className="iconDiv" >
                  <img src={Sec2Icon4} alt="" />
                </div>
                <div className="apyTextDiv">
                  <p>APY VISION</p>
                </div>
                <div className="descTextDiv">
                  <p>
                    A one-stop dashboard for managing your DeFi positions.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="ecosysten-section-3">
          <div >
            <p>
              Information about applications, services, and websites that are not developed, controlled, or tested by Uniswap Labs is provided without recommendation or endorsement. Uniswap Labs assumes no responsibility with regard to the selection, performance, security, accuracy, or use of third-party offerings. For more information on each project, please contact the developer.
            </p>
          </div>
      </section>
      <section className="ecosystem-section-4">
          <Footer />
      </section>
    </Container>
  );
}
