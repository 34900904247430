import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import ContractDetails from '../Contracts/ContractDetails';

const FetchEvenTest = () => {
  const { BigInt } = window;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const web3Instance = new Web3(new Web3.providers.HttpProvider('https://quick-dawn-mound.matic.quiknode.pro/ef8feb268f836873d6b947f5f00822b8e94015cc/'));

        const contractAddress = ContractDetails.Contract;
        const contractABI = ContractDetails.ContractABI;

        const contract = new web3Instance.eth.Contract(contractABI, contractAddress);

        const latestBlockNumber = await web3Instance.eth.getBlockNumber();

        const blockFromFetch = BigInt(57797879);
        const blockToFetch = BigInt(latestBlockNumber);
        let fromBlock = blockFromFetch;

        const allEvents = [];

        // Set chunk size considering the node's capacity (9000 blocks/second)
        const chunkSize = 9000;

        while (fromBlock <= blockToFetch) {
          const toBlock = (Number(fromBlock) + chunkSize - 1 > Number(blockToFetch)) ? 'latest' : Number(fromBlock) + chunkSize - 1;

          const evt = await contract.getPastEvents('swap', {
            fromBlock: Number(fromBlock),
            toBlock: toBlock === 'latest' ? toBlock : Number(fromBlock) + chunkSize - 1,
          });

          allEvents.push(...evt);
          fromBlock = (toBlock === 'latest') ? blockToFetch + BigInt(1) : BigInt(toBlock) + BigInt(1);
        }

        setEvents(allEvents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Custom replacer function to handle BigInt serialization
  const replacer = (key, value) => {
    return typeof value === 'bigint' ? value.toString() : value;
  };

  return (
    <div>
      <h2>Smart Contract Events</h2>
      <ul>
        {events.map((event, index) => (
          <li key={index}>
            <pre style={{color:"white"}}>{JSON.stringify(event, replacer, 2)}</pre>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FetchEvenTest;
