import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";
import { Data } from "./Data";

export async function GetTokenDecimal(tokenA) {
    console.log("Fetching token decimals...",tokenA);

    try {
        const polygonRpcUrl = "https://polygon-rpc.com"; // Replace this with the correct RPC URL for Polygon mainnet

        const provider = new ethers.providers.JsonRpcProvider(polygonRpcUrl);
        const signer = provider.getSigner(ContractDetails.Contract);
        const contractInstance = new ethers.Contract(
            tokenA,
            ContractDetails.USDTABI,
            signer
        );

        Data.isDebug && console.log("Contract instance:", contractInstance);

        const decimals = await contractInstance.decimals();
        Data.isDebug && console.log("Token decimals:", decimals);
        Data.isDebug && console.log("111 Token decimals:", 10**decimals);
        return 10**decimals;
    } catch (error) {
        console.error("Error fetching token decimals:", error);
        return null;
    }
}
